import store from '@/store';


const vuexMixin = {
  
  
  computed: {
    user() {
      return store.getters.user;
    },
    token() {
      return store.getters.token;
    },
    company() {
      return store.getters.company;
    },
    isLoading() {
      return store.getters.isLoading;
    }    
  },
  mounted(){
    
  },
  methods: {
    
    formatDate(inputDate) {
      const dateParts = inputDate.split('-');
      if (dateParts.length !== 3) {
        return inputDate; // Retorna a data original se o formato estiver incorreto
      }

      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];

      return `${day}/${month}/${year}`;
    },
    formatCurrency(value) {
      return parseFloat(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    dateIsGreater(dateString) {
      // Converte a string da data para um objeto Date
      const inputDate = new Date(dateString);
    
      // Obtém a data atual
      const currentDate = new Date();
    
      // Compara as datas
      if (inputDate <= currentDate) {
        return false
      }
      return true
    }
  },
};

export default vuexMixin;
