<template>
  <div class="topbar py-3 px-4">
    <div class="container-fluid p-0">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <picture id="logo" class="d-block">
            <img src="@/assets/storeboost_b.svg" class="d-block"/>
          </picture>            
        </div>
        <div class="col-auto">
          <div class="functions">
            <div class="row align-items-center">
              <div class="col-auto me-3">
                <!-- <div class="company_funds row align-items-center">
                  <div class="col-auto">
                    <img src="@/assets/sb_coin.svg" class="d-block"/>
                  </div>
                  <div class="col ps-0">
                    <strong>
                      {{ company?.funds ? company?.funds : 'R$ 0,00' }}
                    </strong>
                  </div>
                </div> -->
              </div>
              <div class="col-auto">
                <button type="button" class="position-relative d-block p-0">
                  <i class="bi bi-chat"></i>
                  <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                    <span class="visually-hidden">New alerts</span>
                  </span>
                </button>
              </div>
              <div class="col-auto ms-3">
                <button type="button" class="position-relative d-block p-0">
                  <i class="bi bi-question-circle"></i>
                </button>
              </div>
              <div class="col-auto user_info ms-3">
                <div class="row justify-content-end align-items-center">
                  <div class="col-auto">
                    <div class="user_info-thumb">
                      <img v-if="company?.logo?.url" :src="company?.logo?.url">
                      <span v-else>{{ user?.first_name.slice(0,1) || '?' }}</span>
                    </div>
                  </div>
                  <div class="col ps-0">
                    <div class="user_info-name">
                      <small class="user_info-name-username d-block" v-html="user?.first_name || 'Não identificado'"></small>
                      <b class="user_info-name-companyname d-block" v-html="company?.name || 'Não identificado'"></b>
                    </div>
                  </div>
                  <div class="col-auto ps-0">
                    <button
                      class="p-0 user_info-options"
                    >
                      <i class="bi bi-chevron-down"></i>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>        
    </div>
  </div>    
</template>
  
<script>
  
  export default {    
    name: 'TopBar',
    
  };
</script>
  
<style scoped>
  .topbar {
    background-color: #fff;
    color: #131313;
    border-bottom: 1px solid #f2f2f2;
  }
  .functions button{display: block;background: transparent;border: 0;line-height:0}
  .functions button > i{
    font-size: 22px;
  }
  #logo img{
    height: 26px;
  }
  
  .logo a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .menu a {
    text-decoration: none;
    color: #fff;
    margin-left: 20px;
  }
  
  .user_info{}

  .user_info-name-username{color: #848484;line-height:initial;font-size: 12px;}
  .user_info-name-companyname{color:#131313;font-size: 14px;}
  
  .user_info-thumb{
    background: #f2f2f2;
    color: #666;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .user_info-thumb img{max-height:100%}
  .user_info-options{
    background: transparent;
    border: 0;
  }

  .company_funds{}
  .company_funds img{height: 26px;}
  .company_funds strong{font-size: 15px;font-weight: bold;}
</style>
  