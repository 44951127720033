<template>
  <div>
    <div class="row justify-content-between align-items-center mb-5">
      <div class="col-auto"><h1 class="h3">Histórico de Faturas</h1></div>
      <div class="col-auto">
        
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <p>
          <b class="h5">Em aberto</b>
        </p>
        <p>
          Confira as faturas com pagamento em aberto. Regularize o quanto antes o pagamento para evitar interrupção dos serviços.
        </p>
      </div>
      <div class="col">
        <div class="row row-cols-1 g-4" v-if="bills && unpaidBills.length">
          <div class="col listing_item" v-for="item in unpaidBills" :key=item.id>
            <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
              <div class="card-body p-0">
                <div class="row align-items-center">
                  <div class="col">
                    <span v-if="dateIsGreater(item.attributes.due_date)" class="badge text-bg-warning  py-1 px-2">Aguardando Pagamento</span>
                    <span v-if="!dateIsGreater(item.attributes.due_date)" class="badge text-bg-danger  py-1 px-2">Em Aberto</span>
                  </div>                  
                  <div class="col-auto">
                    <p class="m-0">
                      {{ formatDate(item.attributes.due_date) }}
                    </p>
                  </div>
                  <div class="col-auto">
                    <b>{{ formatCurrency(item.attributes.value) }}</b>
                  </div>
                  <div class="col text-right d-flex justify-content-end">
                    <button class="btn small p-0">
                      <i class="bi bi-wallet2"></i>
                      <span class="small ms-2">Efetuar Pagamento</span>
                    </button>
                  </div>
                  
                </div>                
                
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col listing_item no-action">
            <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
              <div class="card-body p-0">
                <div class="row align-items-center justify-content-between">
                  <div class="col d-flex align-items-center">
                    Nenhuma fatura encontrada 
                  </div>                  
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-5">
    <div class="row">
      <div class="col-12 col-md-4">
        <p>
          <b class="h5">Faturas Pagas</b>
        </p>
        <p>
          Consulte pagamentos realizados e baixe suas notas fiscais sempre que precisar.
        </p>
      </div>
      <div class="col">
        <div class="row row-cols-1 g-4" v-if="bills && paidBills.length">
          <div class="col listing_item" v-for="item in paidBills" :key=item.id>
            <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
              <div class="card-body p-0">
                <div class="row align-items-center">
                  <div class="col">
                    <span class="badge text-bg-success  py-1 px-2">Pagamento Confirmado</span>
                    
                  </div>                  
                  <div class="col-auto">
                    <p class="m-0">
                      {{ formatDate(item.attributes.due_date) }}
                    </p>
                  </div>
                  <div class="col-auto">
                    <b>{{ formatCurrency(item.attributes.value) }}</b>
                  </div>
                  <div class="col text-right d-flex justify-content-end">
                    <button class="btn small p-0">
                      <i class="bi bi-files"></i>
                      <span class="small ms-2">NF-e</span>
                    </button>
                  </div>
                  
                </div>                
                
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col listing_item no-action">
            <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
              <div class="card-body p-0">
                <div class="row align-items-center justify-content-between">
                  <div class="col d-flex align-items-center">
                    Nenhuma fatura encontrada 
                  </div>                  
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'PaymentHistory',
  data(){
    return{
      bills:null
    }
  },
  computed:{
    unpaidBills(){
      return this.bills.data.filter(el => el.attributes.payment_status !== "confirmed")
    },  
    paidBills(){
      return this.bills.data.filter(el => el.attributes.payment_status === "confirmed")
    }  
  },  
  methods:{
    openBill(slug){
      this.$router.push(`/widgets/${slug}`);
    }
  },
  async created(){      
      this.$store.commit('SET_LOADING', true)        
      
      try{
        const req = await this.$store.dispatch('_get', {
          endpoint:'bills/',     
          jwt: this.token,     
          data:{
            store: this.company.id
          }
        });
        this.bills = req
        console.log(req)      
      }catch(e){
        console.log(e)
      }
      this.$store.commit('SET_LOADING', false)
    
  }
};

</script>

<style scoped>

</style>