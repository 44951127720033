<template>
  <div>
    <div class="row justify-content-between align-items-center mb-5">
      <div class="col-auto"><h1 class="h3">Dados para Faturamento</h1></div>
      <div class="col-auto">
        
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <p>
          <b class="h5">Informações Complementares</b>
        </p>
        <p>
          Informe os dados que serão utilizados para emissão da nota fiscal dos serviços contratados.
        </p>
      </div>
      <div class="col offset-md-1">
        <div class="row">
          <div class="col">
            <form @submit.prevent="updateInvoiceData" v-if="invoiceData">
              <div class="form-floating">
                <input v-model="invoiceData.name" type="text" class="form-control">
                <label>Nome completo/Razão social</label>
              </div>
              <div class="form-floating mt-4">
                <input v-model="invoiceData.doc" type="text" class="form-control">
                <label>CPF/CNPJ</label>
              </div>
              <div class="form-floating mt-4">
                <input v-model="invoiceData.zip" maxlength="8" @keyup="$event.target.value.length == 8 ? getAddress($event.target.value) : false" type="text" class="form-control">
                <label>CEP</label>
              </div>
              <div class="form-floating mt-4">
                <input v-model="invoiceData.address" type="text" class="form-control">
                <label>Endereço</label>
              </div>
              <div class="row">
                <div class="col-md-4 col-12">
                  <div class="form-floating mt-4">
                    <input v-model="invoiceData.number" type="text" class="form-control">
                    <label>Número</label>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-floating mt-4">
                    <input v-model="invoiceData.address_2" type="text" class="form-control">
                    <label>Complemento</label>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-floating mt-4">
                    <input v-model="invoiceData.district" type="text" class="form-control">
                    <label>Bairro</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-floating mt-4">
                    <input v-model="invoiceData.state" type="text" class="form-control">
                    <label>Estado</label>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-floating mt-4">
                    <input v-model="invoiceData.city" type="text" class="form-control">
                    <label>Cidade</label>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary btn-lg w-100 py-3 mt-4" type="submit">Atualizar</button>             

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'PaymentInfo',
  data(){
    return{
      invoiceData:null
    }
  },
  computed:{
    
  },  
  methods:{
    async updateInvoiceData(){
      this.$store.commit('SET_LOADING', true)  
      try{
        await this.$store.dispatch('_put', {
          endpoint:`stores/${this.company.id}`,
          jwt: this.token,
          data:{
            invoice_data: {
              name: this.invoiceData.name,
              doc: this.invoiceData.doc,
              zip: this.invoiceData.zip,
              address: this.invoiceData.address,
              address_2: this.invoiceData.address_2,
              number: this.invoiceData.number,
              state: this.invoiceData.state,
              city: this.invoiceData.city,
              district: this.invoiceData.district
            }
          }                    
        });
        this.$store.commit('ADD_TOAST',{title:'Sucesso',text:'Informações de faturamento atualizadas',type:'success',duration:5000})
      }catch(e){
        console.log(e)
      }
      this.$store.commit('SET_LOADING', false)  
    },
    async getAddress(zip){
      console.log(zip)
      axios.get(`https://viacep.com.br/ws/${zip}/json/`).then(response => {
            console.log(response)
            if(!response.data.erro){
              this.invoiceData.address = response.data.logradouro
              this.invoiceData.address_2 = response.data.complemento
              this.invoiceData.district = response.data.bairro
              this.invoiceData.city = response.data.localidade
              this.invoiceData.state = response.data.uf
            }
        }).catch(error => {
            console.log(error)
            return []
        })
    }
  },
  async created(){      
      this.$store.commit('SET_LOADING', true)       
      try{
        const req = await this.$store.dispatch('_get', {
          endpoint:'stores',     
          jwt: this.token,     
          data:{
            store: this.company.id
          }
        });
        console.log(req[0]?.invoice_data)
        this.invoiceData = req[0]?.invoice_data ? req[0].invoice_data : {
          name:null,
          doc: null,
          zip: null,
          address: null,
          address_2: null,
          number: null,
          state: null,
          city: null,
          district: null
        }
      }catch(e){
        console.log(e)
      }
      this.$store.commit('SET_LOADING', false)
      
      
      
    
  }
};

</script>

<style scoped>
/* Estilos específicos da página inicial aqui */
</style>