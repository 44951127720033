// import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import authMiddleware from '@/middleware/authMiddleware'
import store from '@/store'; // Importe o store Vuex

import UserSession from '@/views/UserSession.vue'; 
import UserRegister from '@/views/UserRegister.vue'; 
import MainDashboard from '@/views/MainDashboard.vue'; 
import AppList from '@/views/AppList.vue'; 
import AppSign from '@/views/AppSign.vue'; 

//import AppRecordList from '@/views/AppRecordList.vue'; 

//settings
import PaymentInfo from '@/views/PaymentInfo.vue'; 
import PaymentHistory from '@/views/PaymentHistory.vue'; 
import StoreInfo from '@/views/StoreInfo.vue'; 
import StoreUsers from '@/views/StoreUsers.vue'; 

//widgets
import AutoDiscount from '@/views/applications/AutoDiscount.vue'; 
import CustomerReviews from '@/views/applications/CustomerReviews.vue'; 
import FileManager from '@/views/applications/FileManager.vue'; 



const routes = [
  { path: '/', component: UserSession, meta: { middleware: ['guest'] } },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      store.dispatch('logout').then(() => {
        next('/'); // Redireciona para a página inicial após o logout
      });
    },
  },
  { path: '/register', component: UserRegister, meta: { middleware: ['guest'] } },
  {
    path: '/dashboard',
    component: MainDashboard,
    meta: { middleware: ['auth'] }, // Requer autenticação
  },
  {
    path: '/settings/payment',
    component: PaymentInfo,
    meta: { middleware: ['auth'] }, // Requer autenticação
  },
  {
    path: '/settings/history',
    component: PaymentHistory,
    meta: { middleware: ['auth'] }, // Requer autenticação
  },
  {
    path: '/settings/store',
    component: StoreInfo,
    meta: { middleware: ['auth'] }, // Requer autenticação
  },
  {
    path: '/settings/users',
    component: StoreUsers,
    meta: { middleware: ['auth'] }, // Requer autenticação
  },
  {
    path: '/widgets',
    component: AppList,
    meta: { middleware: ['auth'] }, // Requer autenticação
  },
  {
    path: '/app/customer-reviews',
    component: CustomerReviews,
    props:{appId:5},
    meta: { middleware: ['auth'] },
  },
  {
    path: '/app/file-manager',
    component: FileManager,
    props:{appId:7},
    meta: { middleware: ['auth'] },
  },
  {
    path: '/app/auto-discount',
    component: AutoDiscount,
    meta: { middleware: ['auth'] },
  },
  {
    path: '/widgets/sign/:id',
    component: AppSign,
    meta: { middleware: ['auth'] }, // Requer autenticação
  },
  // {
  //   path: '/widgets/auto-discount',
  //   component: AutoDiscount,
  //   meta: { middleware: ['auth'] }, // Requer autenticação
  // }
//   { path: '/criar', component: CriarRecurso },
//   { path: '/editar/:id', component: EditarRecurso, props: true },
  // Defina outras rotas aqui
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(authMiddleware); // Use o middleware em todas as rotas


export default router
