<template>
    <div class="app-header d-flex justify-content-between align-items-center" v-if="app">
      <h1 v-if="app?.name"><img class="me-2" v-if="app?.cover?.data?.attributes?.url" :src="app.cover.data.attributes.url">
        {{ app.name }}
      </h1>
      
      <a  href="/app/customer-reviews/config" class="btn btn-sm btn-link">
        <i class="bi bi-gear me-1"></i>
        Configurações
      </a>
    </div>
    <div class="app-list" v-if="app">
      <AppContentList 
        :options="{create: false,read: false,delete: true,update: false}"     
        :ordenations="{}"
        :filters="{}"
        :searchable="{}"
        :data="appData"
        :appId="7"
        :schema="{
          '[data][image][0][formats][thumbnail][url]': 'Image',
          '[data][image][0][name]': 'String',         
          '[data][image][0][url]':'InputText',    
        }"
        :instructions="{
          title:'Gerencie seus Arquivos',
          description:'Aqui um texto explicativo'
        }"
        @updateData="appData = $event"
      />
    </div>
  </template>
  
  <script>
  import AppContentList from '../../components/AppContentList.vue'
  export default {
    name: 'CustomerReviews',
    data(){
      return{
        app:null,
        appData:null
      }
    },
    props:{
      appId:Number
    },
    components: {
      AppContentList     
    },
    computed:{
      widgetData(){
        return {}
      }
    },
    methods:{
      
    },
    async created(){
      this.$store.commit('SET_LOADING', true)        
        this.$store.commit('SET_BCRUMB', [
            { url: '/widgets', label: 'Voltar' },  
            { url: '/', label: 'Início' },
            { url: '/widgets', label: 'Aplicativos' },              
        ]);  
        try{
          const req = await this.$store.dispatch('_get', {
            endpoint:`widgets/${this.appId}?populate=*`,     
            jwt: this.$store.getters.token       
          });
          this.app = req.data.attributes       
          try{
          const req_ = await this.$store.dispatch('_get', {
              endpoint:`widgets-data`,     
              jwt: this.$store.getters.token,
              data:{
                appId:this.appId,
                storeId: this.company.id
              }       
            });
            this.appData = req_
          }catch(e){
            console.log(e)
          }  
        }catch(e){
          console.log(e)
        }
        
        
      
        this.$store.commit('SET_LOADING', false)
    }
  };
  </script>
  
  <style scoped>
  /* Estilos específicos da página inicial aqui */
  </style>