/* eslint-disable no-unused-vars */
import { createStore } from 'vuex';
import strapi from '@/services/strapi';
import router from '@/router'; // Importe o router

const store = createStore({
    state: {
        user: getStoredUser(), // Chame a função para obter o usuário armazenado
        company: getStoredCompany(), // Chame a função para obter o usuário armazenado
        token: getStoredToken(), // Chame a função para obter o token armazenado
        isAuthenticated: getStoredToken() != null ? true : false,
        isLoading: false,
        toasts:[],
        breadcrumb:[]
    },
  mutations: {
    SET_BCRUMB(state, breadcrumb) {
        state.breadcrumb = breadcrumb;
      },
    SET_USER(state, { user, expiration }) {
        state.user = user;
        saveToLocalStorage('user', user, expiration); // Salve o usuário no localStorage com data de expiração
      },
      SET_COMPANY(state, { company, expiration }) {
        console.log('set_company',company)
        state.company = company;
        saveToLocalStorage('company', company, expiration); // Salve o usuário no localStorage com data de expiração
      },
      SET_TOKEN(state, { token, expiration }) {
        state.token = token;
        saveToLocalStorage('token', token, expiration); // Salve o token no localStorage com data de expiração

      },
      SET_AUTHENTICATED(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
      },
      SET_LOADING(state, isLoading) { // Adicione a mutação para definir o estado de carregamento
        state.isLoading = isLoading;
      },
      ADD_TOAST(state,toast){      
        state.toasts.push({title: toast.title,type: toast.type,text:toast.text, duration:toast.duration})
        
        
        //$('[data-toast-title="'+ title +'"]').fadeIn()
        setTimeout(() => {
          const indexToRemove = state.toasts.findIndex(el => el.title === toast.title);
          if (indexToRemove !== -1) {
            state.toasts.splice(indexToRemove, 1);
          }
        }, toast.duration);
        
      },
  },
  actions: {
    async login({ commit }, ctx) {
      // Implement authentication logic here and call the appropriate mutations
        commit('SET_LOADING', true)  
        try {
            //console.log(ctx)
            
            const r = await strapi.login(ctx.email, ctx.password)
                    
            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 1); // Define a expiração para 1 dia a partir de agora
            commit('SET_TOKEN', { token: r.data.jwt, expiration });
            commit('SET_USER', { user: r.data.user, expiration });
            commit('SET_AUTHENTICATED', true);

            const company = await strapi._get({
                jwt:r.data.jwt,
                endpoint: 'stores'
            })
            
            await commit('SET_COMPANY', { company: company?.[0], expiration });
            

            router.push('/dashboard');            
        }catch(e){            
            console.error('Auth Err:', e);
            commit('SET_TOKEN', { token: null, expiration: null });
            commit('SET_USER', { user: null, expiration: null });
            commit('SET_AUTHENTICATED', false);
            if(e.response && e.response.data && e.response.data.error){
                alert(e.response.data.error.message)
            }
        }
        commit('SET_LOADING', false)
    },
    async logout({ commit }) {
        commit('SET_TOKEN', { token: null, expiration: null });
        commit('SET_USER', { user: null, expiration: null });
        commit('SET_COMPANY', { company: null, expiration: null });
        commit('SET_AUTHENTICATED', false);
  
        // Redirecionar para a página de login
        router.push('/');
    },

    async register({commit}, ctx){
        try{
            console.log(ctx)
            const r = await strapi.register(ctx)
            
            await this.dispatch('login', ctx);
            
        }catch(e){
            console.error('Register Err:', e);
            if(e.response && e.response.data && e.response.data.error){
                alert(e.response.data.error.message)
            }
        }
    },
    async _post({commit}, ctx){
        try{
            console.log(ctx)
            const r = await strapi._post(ctx)
            
            return r            
        }catch(e){
            console.error('_post Err:', e);
            if(e.response && e.response.data && e.response.data.error){
                alert(e.response.data.error.message)
            }
            return e.data.error
        }
    },
    async _put({commit}, ctx){
        try{
            console.log(ctx)
            const r = await strapi._put(ctx)
            
            return r            
        }catch(e){
            console.error('_put Err:', e);
            if(e.response && e.response.data && e.response.data.error){
                alert(e.response.data.error.message)
            }
            return e.data.error
        }
    },
    async _delete({commit}, ctx){
        try{
            
            const r = await strapi._delete(ctx)
            
            return r            
        }catch(e){
            console.error('_delete Err:', e);
            if(e.response && e.response.data && e.response.data.error){
                alert(e.response.data.error.message)
            }
            return e.data.error
        }
    },
    async _multiform({commit}, ctx){
        try{
            console.log(ctx)
            const r = await strapi._multiform(ctx)
            
            return r            
        }catch(e){
            console.error('_multiform Err:', e);
            if(e.response && e.response.data && e.response.data.error){
                alert(e.response.data.error.message)
            }
            return e.data.error
        }
    },
    async _get({commit}, ctx){
        try{
            console.log(ctx)
            const r = await strapi._get(ctx)
            
            return r            
        }catch(e){
            console.error('_get Err:', e);
            if(e.response && e.response.data && e.response.data.error){
                alert(e.response.data.error.message)
            }
            return e.data.error
        }
    }
  },
  getters: {
    user: (state) => state.user,
    company: (state) => state.company,
    token: (state) => state.token,
    isAuthenticated: (state) => state.isAuthenticated,
    isLoading: (state) => state.isLoading
  },
});

export default store;

// Função para obter o usuário armazenado
function getStoredUser() {
const userData = JSON.parse(localStorage.getItem('user'));
if (userData && new Date(userData.expiration) > new Date()) {
    return userData.value;
}
saveToLocalStorage('user',null)
saveToLocalStorage('company',null)
saveToLocalStorage('token',null)
return null;
}

function getStoredCompany() {
    const companyData = JSON.parse(localStorage.getItem('company'));
    if (companyData && new Date(companyData.expiration) > new Date()) {
        return companyData.value;
    }
    return null;
}
    

// Função para obter o token armazenado
function getStoredToken() {
const tokenData = JSON.parse(localStorage.getItem('token'));
if (tokenData && new Date(tokenData.expiration) > new Date()) {
    return tokenData.value;
}
saveToLocalStorage('user',null)
saveToLocalStorage('company',null)
saveToLocalStorage('token',null)
return null;
}

// Função para salvar dados no localStorage com data de expiração
function saveToLocalStorage(key, value, expiration) {
    localStorage.setItem(key, JSON.stringify({ value, expiration }));
}