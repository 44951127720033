<template>
    <div>
      <div class="row align-items-center justify-content-between">
        <div class="col-auto">
          <h1 class="h3">Instalar</h1>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto">
              <button class="btn btn-sm font-bold" @click="this.$router.push(`/widgets/`)">
                Cancelar
              </button>
            </div>
            <div class="col-auto" v-if="widget?.data">
              <button @click="signWidget()" class="btn btn-primary btn-sm font-bold py-3 px-4">Instalar por R$ {{ formatCurrency(widget?.data.attributes.price) }}/mês</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="panel p-4 mt-4" v-if="widget?.data">
            <h2 class="f16 d-block mb-2">{{ widget.data.attributes.name }}</h2>
            <hr class="my-4">
            <p class="pre-formatted">{{ widget.data.attributes.description_long  }}</p>
            <hr class="my-4">
            <div>
              <b class="d-block mb-3 font-bolder">VOCÊ ESTÁ INSTALANDO UM NOVO APLICATIVO</b>
              <p>Ao instalar um novo aplicativo você concorda em compartilhar informações da sua loja virtual com este sistema. Alguns aplicativos terão acesso à base de clientes e pedidos, por exemplo.</p>
            </div>
          </div>  
        </div>
      </div>
          
    </div>
  </template>
  
  <script>
  export default {
    name: 'AppList',
    data(){
      return{
        widget:null,
        subscribed:null,
      }
    },
    methods:{
      async signWidget(){
        this.$store.commit('SET_LOADING', true)        
        try{
          await this.$store.dispatch('_post', {
            endpoint:`signatures/`,     
            jwt: this.$store.getters.token,
            data:{
              store: this.company.id,
              widget: this.$route.params.id,
              signed_by: this.user.id,
              price_at_sign: this.widget?.data.attributes.price
            }     
          });
          this.$router.push(`/widgets`);
        }catch(e){
          console.log(e)
        }
        this.$store.commit('SET_LOADING', false)
      }
    },
    async created(){      
        this.$store.commit('SET_LOADING', true)        
        try{
          const req = await this.$store.dispatch('_get', {
            endpoint:`widgets/${this.$route.params.id}`,     
            jwt: this.$store.getters.token       
          });
          this.widget = req         
        }catch(e){
          console.log(e)
        }
        this.$store.commit('SET_LOADING', false)
      
    }
  };
  
  </script>
  
  <style scoped>
  /* Estilos específicos da página inicial aqui */
  </style>