<template>
  <main class="form-signin w-100 m-auto">
    <form @submit.prevent="loginUser">
      <picture id="logo" class="d-block mx-auto">
        <img src="@/assets/storeboost_b.svg" class="d-block mx-auto" />
      </picture>
      <div class="form-floating mt-5">
        <input v-model="email" type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
        <label for="floatingInput">Email*</label>
      </div>
      <div class="form-floating mt-4">
        <input v-model="password" type="password" class="form-control" id="floatingPassword" placeholder="Password">
        <label for="floatingPassword">Senha*</label>
      </div>

      <button class="btn btn-primary btn-lg w-100 py-2 mt-4" type="submit">Login</button>

      <div class="d-flex align-items-center w-100 justify-content-center mt-3">
        <a href="#" class="h6 lost-password">Esqueci minha senha</a>
      </div>
    </form>
  </main>
</template>

<script>
export default {
  name: 'UserSession',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    loginUser() {
      // Call the Vuex login action with user credentials
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style scoped>
#logo img {
  height: 36px;
}
.form-signin {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 100vh;
  z-index: 99999999999999999;
}
.form-signin form {
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 400px;
  max-width: 100%;
}
.lost-password {
  text-decoration: none;
  color: #424242;
  border-bottom: 1px solid;
}
/* Specific styles for the login page here */
</style>
