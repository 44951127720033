<template>
    <div class="container my-5">
      <div class="row">
        
        <div class="col-12 col-md-4" v-if="instructions?.title && instructions?.description">
          <p>
            <b class="h5">{{ instructions.title }}</b>
          </p>
          <p>
            {{ instructions.description }}            
          </p>          
          <p class="small mt-3">Está enfrentando um problema? <a href='#'>Clique aqui</a></p>           
        </div>
        <div class="col offset-md-1" id="widget-content-list">
          <div class="row">
            <div class="col">
              <form class="search" v-if="searchable?.enabled">
                <div>
                  <input type="text" class="form-control" placeholder="Pesquisar...">
                  <button type="submit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                    </svg>
                  </button>
                </div>
              </form>
              <div v-if="data && data.length > 0">
                <div class="widget-content-list-items row row-cols-1 g-4">
                  <div class="col listing_item mt-1" v-for=" (item,index) in data" :key="item.id">
                    <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
                      <div class="card-body p-0"> 
                        <div class="row align-items-center d-flex">
                          <div class="col-auto field-col" v-for="(field, key) in schema" :key="item.id + '_' + field"
                          :class="'item_' + key.replaceAll('[','_').replaceAll(']','_') + ' item_' + field.replaceAll('[','_').replaceAll(']','_')">
                         
                           <button v-if="field == 'Toggle' && key == '[active]'" class="btn-sm btn" :class="getField(item,key) ? 'btn-outline-success' : 'btn-outline-danger'">
                              {{ getField(item,key) == 'true' ? 'Ativo' : 'Inativo' }}
                            </button>
                            
                            <img class="item_thumbnail" v-if="field == 'Image'" :src="getField(item,key)"/>

                            <input type="text" readonly class="form-control" v-if="field == 'InputText'" :value="getField(item,key)"/>
                            
                            <label v-if="field == 'String'">{{ getField(item,key) }}</label>
                            <label v-if="field == 'Currency'">{{ formatCurrency(getField(item,key)) }}</label>
                            <label v-if="field == 'Number' && key.includes('rating')">
                              <i class="bi bi-star-fill"></i>
                              {{ getField(item,key) }}/5
                            </label>
                          </div> 
                          <div class="col-auto" v-if="options?.delete == true">
                            <button @click="doDelete(item, index)" type="button" class="btn btn-outline-danger btn-sm">
                              <i class="bi bi-trash"></i>                              
                            </button>
                          </div>                                           
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row"><div class="col listing_item no-action"><div class="card d-flex flex-row align-items-start p-3" aria-hidden="true"><div class="card-body p-0"><div class="row align-items-center justify-content-between"><div class="col d-flex align-items-center"> Nenhum registro encontrado :(</div></div></div></div></div></div>
                
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </template>
  
  <script>
  import ConfirmDialogue  from './ConfirmDialogue.vue'
  export default {
    components: { ConfirmDialogue  },
    name: 'AppContentList',
    props: {
      data: Object,
      options:Object,
      filters:Object,
      ordenations:Object,
      searchable:Object,
      instructions:Object,
      appId: String,
      schema:Object
    },
    
    data(){
      return{
       
      }
    },
    computed:{
    },
    methods:{
      
      getField(item,pathString){
        const keys = pathString.match(/\w+/g);
        const value = keys.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, item);
        
        return value

      },
      async doDelete(item){
        const ok = await this.$refs.confirmDialogue.show({
            title: 'Deseja remover este item?',
            message: 'Esta operação não pode ser desfeita. Deseja prosseguir?',
            okButton: 'Sim, deletar',
            cancelButton:'Cancelar'
        })
        if (ok) {          
          try{
            this.$store.commit('SET_LOADING', true)    
            const req = await this.$store.dispatch('_delete', {
              endpoint:`widgets-data/${item.id}`,
              headers:{'App-Id': this.appId},                     
            });
            if(req){
              let data = this.data.filter(el => el.id != item.id)
              this.$emit('updateData', data);
              this.$store.commit('SET_LOADING', false)    
              this.$store.commit('ADD_TOAST',{title:'Sucesso',text:`O item ${item.data.image[0].name} foi removido`,type:'success',duration:5000})
            }else{
              this.$store.commit('SET_LOADING', false)    
              this.$store.commit('ADD_TOAST',{title:'Erro',text:`O item ${item.data.image[0].name} não foi removido. Tente novamente mais tarde ou entre em contato com o suporte.`,type:'error',duration:5000})
            }
            
          }catch(err){
            this.$store.commit('ADD_TOAST',{title:'Erro',text: err ,type:'error',duration:5000})  
          }
        } else {
            //alert('You chose not to delete this page. Doing nothing now.')
        }
      }

    },
    created(){      
       
    }
  };
  
  </script>
  
  <style scoped>
  /* Estilos específicos da página inicial aqui */
  </style>