<template>
    <div>
      <h1 class="h3">Olá, {{ user.first_name }}!</h1>
    </div>  
     
  </template>
  
  <script>
  export default {
    name: 'MainDashboard',
  };
  </script>
  
  <style scoped>
  /* Estilos específicos da página inicial aqui */
  </style>