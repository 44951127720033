<template>
    <nav aria-label="breadcrumb" v-if="breadcrumb && breadcrumb.length > 0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" :class="key == item.length - 1 ? 'active' : ''" v-for="(item, key) in breadcrumb" :key="key">
          <a :href="item.url" v-if="item.url">
            {{ item.label }}
          </a>
          <span v-else>
            {{ item.label }}
          </span>
        </li>
        
      </ol>
    </nav>
</template>
    
  <script>
    export default {
      name: 'BreadCrumb',
      computed: {
        breadcrumb() {
          
          return this.$store.state.breadcrumb;
        },
        
      },
    };
  </script>
    
  <style scoped>
  </style>
    