<template>
    <div v-if="isLoading" class="loading-spinner">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
</template>
  
<script>
export default {
    mounted(){
        
    }
};
</script>

<style scoped>
/* Estilos do spinner */
</style>
