<template>
    <div>
      <div class="row justify-content-between align-items-center mb-5">
        <div class="col-auto"><h1 class="h3">Aplicativos</h1></div>
        <div class="col-auto">
          
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <p>
            <b class="h5">Aplicativos Instalados</b>
          </p>
          <p>
            Confira os aplicativos que já foram instalados em sua loja virtual ou acesse a loja de aplicativos para mais opções.
          </p>
        </div>
        <div class="col">
          <div class="row row-cols-1 row-cols-md-2 g-4" v-if="signedWidgets?.data?.length && widgets?.data?.length">
            <div class="col listing_item" v-for="item in signedWidgets.data" :key=item.attributes.widget.data.id>
              <div @click="openWidget(item.attributes.widget.data.attributes.view)" class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
                <div class="d-flex align-items-center justify-content-center">
                  <img :src="getWidget(item.attributes.widget.data.id).attributes.cover.data.attributes.url" class="card-img-top">
                </div>
                <div class="card-body pt-0 ps-4 pr-0 pb-0">
                  <div class="row align-items-start">
                    <div class="col">
                      <strong class="card-title d-block mb-2">{{ getWidget(item.attributes.widget.data.id).attributes.name }}</strong>
                    </div>
                    <div class="col-auto pe-0">
                      <i class="bi bi-gear"></i>
                    </div>
                  </div>
                  
                  <p class="card-text">{{ getWidget(item.attributes.widget.data.id).attributes.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col listing_item no-action">
              <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
                <div class="card-body p-0">
                  <div class="row align-items-center justify-content-between">
                    <div class="col d-flex align-items-center">
                      Nenhum aplicativo instalado
                    </div>                  
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-5">
      <div class="row">
        <div class="col-12 col-md-4">
          <p>
            <b class="h5">Aplicativos Disponíveis</b>
          </p>
          <p>
            Confira os aplicativos que já foram instalados em sua loja virtual ou acesse a loja de aplicativos para mais opções.
          </p>
        </div>
        <div class="col">
          <div class="row row-cols-1 row-cols-md-2 g-4" v-if="widgets && signedWidgets">
            <div class="col listing_item" v-for="item in availableWidgets" :key=item.id>
              <div @click="signWidget(item.id)" class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
                <div class="d-flex align-items-center justify-content-center">
                  <img :src="item.attributes.cover.data.attributes.url" class="card-img-top">
                </div>
                <div class="card-body pt-0 ps-4 pr-0 pb-0">
                  <div class="row">
                    <div class="col">
                      <strong class="card-title d-block mb-2">{{ item.attributes.name }}</strong>
                    </div>
                    <div class="col-auto pe-0">
                      <i class="bi bi-upload"></i>
                    </div>
                  </div>                  
                  <p class="card-text">{{ item.attributes.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
          <div class="col listing_item no-action">
            <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
              <div class="card-body p-0">
                <div class="row align-items-center justify-content-between">
                  <div class="col d-flex align-items-center">
                    Nenhum aplicativo disponível
                  </div>                  
                </div>  
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'AppList',
    data(){
      return{
        widgets:null,
        signedWidgets:null
      }
    },
    computed:{
      availableWidgets() {
        if (!this.widgets || !this.signedWidgets) {
          // Se uma das variáveis for null, retorne um array vazio ou outro valor padrão
          return [];
        }
      console.log(this.widgets)
      console.log(this.signedWidgets)
        const available = this.widgets.data.filter(widget => {
          // Verifique se o widget.id não existe em signedWidgets
          return !this.signedWidgets.data.some(signedWidget => signedWidget.attributes.widget.data.id === widget.id);
        });
        return available
      }    
    },  
    methods:{
      signWidget(id){
        this.$router.push(`/widgets/sign/${id}`);
      },
      openWidget(slug){
        this.$router.push(`/app/${slug}`);
      },
      getWidget(id){
        return this.widgets.data.find(el => el.id == id)
      },
      
    },
    async created(){      
        this.$store.commit('SET_LOADING', true)        
        
        try{
          const req = await this.$store.dispatch('_get', {
            endpoint:'signatures/',     
            jwt: this.token,     
            data:{
              store: this.company.id
            }
          });
          this.signedWidgets = req
          console.log(req)      
        }catch(e){
          console.log(e)
        }
        
        try{
          const req = await this.$store.dispatch('_get', {
            endpoint:'widgets?populate=cover',     
            jwt: this.$store.getters.token       
          });
          this.widgets = req         
        }catch(e){
          console.log(e)
        }
        
        this.$store.commit('SET_LOADING', false)
      
    }
  };
  
  </script>
  
  <style scoped>
  /* Estilos específicos da página inicial aqui */
  </style>