<template>
    <div class="toast-container top-0 end-0  p-4 position-fixed" v-if="this.$store.state.toasts">
      <div v-for="item in this.$store.state.toasts" :key=item.text>
        <div class="toast show" :data-toast-title="item.title" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="500">
          <div class="toast-header">   
            <span :class="'badge text-bg-' + item.type + ' p-2 me-2 d-block'"></span>            
            <strong class="me-auto">{{ item.title }}</strong>            
          </div>
          <div class="toast-body">
            {{ item.text }}
          </div>
        </div>        
      </div>
    </div>
</template>
    
  <script>
    export default {
      name: 'ToastAlert',
    };
  </script>
    
  <style scoped>
  </style>
    