<template>  
  <TopBar v-if="user"/>
  <MainMenu v-if="user"/>
  
  <div class="apx p-md-5 p-4">    
    <BreadCrumb></BreadCrumb>
    <router-view></router-view>
  </div>
  <CompanySetup v-if="user && !company"/>
  
  <LoadingSpinner/>
  <ToastAlert/>
</template>

<script>
import MainMenu from './components/MainMenu.vue'
import TopBar from './components/TopBar.vue'
import ToastAlert from './components/ToastAlert.vue'
import BreadCrumb from './components/BreadCrumb.vue'
import CompanySetup from './components/CompanySetup.vue'
import LoadingSpinner from './components/LoadingSpinner.vue' 

export default {
  name: 'App',
  components: {
    MainMenu,
    TopBar,
    ToastAlert,
    BreadCrumb,
    CompanySetup,
    LoadingSpinner
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

#app{
  display: grid;
  grid-template-areas:
      "topbar topbar"
      "menu router";
  grid-template-columns: 280px auto;
  grid-template-rows: auto 1fr;
  height: 100vh;
}
#app > .topbar{
  grid-area: topbar;
}
#app > .menu{
  grid-area: menu;
  width: 100%;
  height: 100%;
}
.apx{
  background: #f8f8f8;
  grid-area: router;
}

body .btn-primary {
  
    --bs-btn-color: #fff;
    --bs-btn-bg: #B530CE;
    --bs-btn-border-color: #B530CE;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #941ea9;
    --bs-btn-hover-border-color: #941ea9;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #941ea9;
    --bs-btn-active-border-color: #941ea9;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #B530CE;
    --bs-btn-disabled-border-color: #B530CE;
}
</style>
