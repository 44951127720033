<template>
    <nav class="menu">
      <ul class="d-flex flex-column py-2">
        <li class="px-2">
          <router-link to="/" class="row align-items-center p-3">
            <div class="col-auto">
              <i class="bi bi-house-door"></i>
            </div>
            <div class="col">
              Início
            </div>
          </router-link>
        </li>
        
        <li class="divider my-2">

        </li>
        <li class="px-2">
          <router-link to="/widgets" class="row align-items-center p-3">
            <div class="col-auto">
              <i class="bi bi-layers"></i>
            </div>
            <div class="col">
              Aplicativos
            </div>
          </router-link>
        </li>
        <li class="px-2">
          <router-link to="/widgets" class="row align-items-center p-3">
            <div class="col-auto">
              <i class="bi bi-arrow-left-right"></i>
            </div>
            <div class="col d-flex align-items-center">
              Integrações <span class="badge text-bg-danger small py-1 px-2 ms-3">EM BREVE</span>
            </div>
          </router-link>
        </li>
        <li class="px-2">
          <router-link to="/benefits" class="row align-items-center p-3">
            <div class="col-auto">
              <i class="bi bi-gift"></i>
            </div>
            <div class="col">
              Benefícios
            </div>
          </router-link>
        </li>
        <li class="px-2">
          <button @onclick="toggle" class="dropdown-toggle d-flex align-items-center justify-content-between w-100 p-3" type="button" 
            aria-expanded="false"            
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <i class="bi bi-wallet2"></i>
              </div>
              <div class="col">
                Financeiro
              </div>
            </div>
          </button>
          <ul class="dropdown-menu flex-column w-100 position-relative ps-5">
            <li class="pb-2 ps-2"><router-link to="/settings/payment" >Dados para pagamento</router-link></li>
            <li class="pb-2 ps-2"><router-link to="/settings/history" >Histórico de faturas</router-link></li>
          </ul>
        </li>
        <li class="px-2" v-if="company?.admin_user.id == user?.id">
          <button class="dropdown-toggle d-flex align-items-center justify-content-between w-100 p-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="row align-items-center">
              <div class="col-auto">
                <i class="bi bi-gear"></i>
              </div>
              <div class="col">
                Configurações
              </div>
            </div>
          </button>
          <ul class="dropdown-menu flex-column w-100 position-relative ps-5">
            <li  class="pb-2 ps-2"><router-link to="/settings/store" >Dados da loja</router-link ></li>
            <li class="pb-2 ps-2"><router-link to="/settings/users" >Usuários</router-link ></li>            
          </ul>
        </li>
        <!-- Adicione mais links do menu aqui, se necessário -->
      </ul>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'MainMenu',

   
  };
  </script>
  
  <style scoped>
  .menu {
    background-color: #131313;
    color: #fff;
  }
  
  .menu ul {
    list-style-type: none;
    padding: 0;
    display: flex;
  }
  
  .menu li {
    
  }
  .menu li.divider{
    background: #424242;
    height: 1px;
  }
  
  .menu > ul > li > a,
  .menu > ul > li > button {
    text-decoration: none;
    color: #fff;
    background: transparent;
    border: 0;    
    font-size: 16px;
  }
  .menu > ul > li > button  + .dropdown-menu{
    background: transparent;
  }
  .menu > ul > li > button  + .dropdown-menu > li{}
  .menu > ul > li > button  + .dropdown-menu > li > a{
    color: #848484;
    text-decoration: none;
  }
  .menu > ul > li > button  + .dropdown-menu > li > a:hover{
    color: #fff;
  }
  
  /* Estilize o componente de acordo com suas preferências */
  </style>
  