import { createApp } from 'vue';
import { plugin, defaultConfig } from '@formkit/vue'
import App from './App.vue';
import router from './router';
import store from './store';
import vuexMixin from './vuexMixin';



import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import 'bootstrap'

// import strapi from './services/strapi';

// const store = createStore({
//   state () {
//     return {
//       user:null,
//       permissions:null,
//       cms:null,
//       components:null,
//       store:null,
//       plan:null

//     }
//   },
//   mutations: {
//     // increment (state) {
//     //   state.count++
//     // }
//   },
//   actions:{
//     login (ctx){
//       const req = await strapi.login(ctx.email, ctx.password)
//       if(ctx.jwt){

//       }
//     }
//   }
// })

const app = createApp(App)

app
.use(store)  
.use(router)  
.use(plugin, defaultConfig)
.mixin(vuexMixin) 
.mount('#app');
