import axios from 'axios'
import store from '@/store';
const strapi = {
    //API_URL: 'http://localhost:1337/api',
    API_URL: 'https://api.storeboost.com.br/api',
//    API_URL: 'https://storeboost.up.railway.app/api',

    async login(mail, password){
        const r = await axios.post(`${this.API_URL}/auth/local`, {
            identifier: mail,
            password: password            
        })

        return r        
    },

    async register(ctx){
        const r = await axios.post(`${this.API_URL}/auth/local/register`, {
            username: (ctx.first_name.replaceAll(' ','').slice(0,1).trim().toLowerCase() + ctx.last_name.replaceAll(' ','').trim().toLowerCase()),
            email: ctx.email,
            first_name: ctx.first_name,
            last_name : ctx.last_name,
            gender: ctx.gender,
            doc: ctx.doc,
            birth_date: ctx.birth_date,
            lgpd:ctx.lgpd,
            terms:ctx.terms,
            phone: ctx.phone,
            password: ctx.password            
        })
        return r        
    },

    async _post (ctx) {
        let headers = {
            Authorization: `Bearer ${ctx.jwt}`
        }
        if(ctx.headers){
            let headers_ = {...headers, ...ctx.headers}
            headers = headers_
        }
        return axios.post(`${this.API_URL}/${ctx.endpoint}`, {data:ctx.data}, {
            headers: headers
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },
    async _put (ctx) {
        let headers = {
            Authorization: `Bearer ${ctx.jwt}`
        }
        if(ctx.headers){
            let headers_ = {...headers, ...ctx.headers}
            headers = headers_
        }
        return axios.put(`${this.API_URL}/${ctx.endpoint}`, {data:ctx.data}, {
            headers: headers
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },

    async _delete (ctx) {
        let headers = {
            Authorization: `Bearer ${store.getters.token}`,
        }
        if(ctx.headers){
            let headers_ = {...headers, ...ctx.headers}
            headers = headers_
        }
        headers['Store-Id'] = store.getters.company.id 
        console.log(`${this.API_URL}/${ctx.endpoint}`)
        
        return axios.delete(`${this.API_URL}/${ctx.endpoint}`, {
            headers: headers
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },

    async _multiform (ctx) {
        let headers = {
            Authorization: `Bearer ${ctx.jwt}`,
            'Content-Type': 'multipart/form-data',
        }
        return axios.post(`${this.API_URL}/${ctx.endpoint}`, ctx.data, {
            headers: headers
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },


    async _get (ctx) {
        return axios.get(`${this.API_URL}/${ctx.endpoint}`, {
            headers: {
                Authorization: `Bearer ${ctx.jwt}`
            },
            params: ctx.data
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },
}

export default strapi
