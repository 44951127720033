<template>
    <div>
      <div class="row justify-content-between align-items-center mb-5">
        <div class="col-auto"><h1 class="h3"><small>Aplicativo</small><br>Cupom Automático</h1></div>
        <div class="col-auto">
          <button type="button"  class="btn btn-primary btn-sm font-bold small py-2 px-3 btn-add">
            <i class="bi bi-plus me-2"></i> <span class="small">Adicionar Regra</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <p>
            <b class="h5">Instruções</b>
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh viverra non semper suscipit posuere a pede.
<br><br>
Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.
          </p>

        </div>
        <div class="col offset-md-1" id="widget-content-list">
          <div class="row">
            <div class="col">
              <form class="search">
                <div>
                  <input type="text" class="form-control" placeholder="Pesquisar...">
                  <button type="submit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                    </svg>
                  </button>
                </div>
              </form>
              <hr>
              <div>
                <div class="widget-content-list-items row row-cols-1 g-4">
                  <div class="col listing_item">
                    <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
                      <div class="card-body p-0"> 
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <button type="button" class="status">
                            </button>
                          </div>
                          <div class="col">
                            Título do registro
                          </div>                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AutoDiscount',
  };
  </script>
  
  <style scoped>
  /* Estilos específicos da página inicial aqui */
  </style>