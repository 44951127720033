// import store from '@/store'

// export default function authMiddleware({ next, router }) {
//     if (!store.getters.isAuthenticated) {
//         return router.push({ name: 'UserSession' });
//     }
//     next();
// }
  
import store from '@/store'; // Importe o Vuex Store

export default function authMiddleware(to, from, next) {
  const isAuthenticated = store.getters.isAuthenticated;
  
  if (to.matched.some((record) => record.meta.middleware)) {
    const middleware = to.meta.middleware;

    if (middleware.includes('auth') && !isAuthenticated) {
      // Redirecione para a página de login se não estiver autenticado
      next('/');
    } else if (middleware.includes('guest') && isAuthenticated) {
      // Redirecione para o dashboard se já estiver autenticado
      next('/dashboard');
    } else {
      next();
    }
  } else {
    next();
  }
}
