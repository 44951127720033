<template>
  <div>
    <div class="row justify-content-between align-items-center mb-5">
      <div class="col-auto"><h1 class="h3">Usuários</h1></div>
      <div class="col-auto">
        
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <p>
          <b class="h5">Usuário Administrativo</b>
        </p>
        <p>
          Responsável pela gestão do cadastro e usuários da empresa vigente.
        </p>
      </div>
      <div class="col">
        <div class="row row-cols-1 g-4" v-if="current_company">
          <div class="col listing_item">
            <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
              <div class="card-body p-0">
                <div class="row align-items-center justify-content-between">
                  <div class="col d-flex align-items-center">
                    <i class="bi bi-person-lock me-2"></i> 
                    <b>{{ current_company.admin_user.first_name + ' ' + current_company.admin_user.last_name}}</b>
                    <span v-if="current_company.admin_user.id == user.id" class="badge  ms-2 text-bg-success  py-1 px-2">Este é Você</span>                    
                  </div>
                  <div class="col">
                    {{ current_company.admin_user.email }}
                  </div>
                  <div class="col-auto">
                    <button class="btn small p-0">
                      <i class="bi bi-key"></i>
                      <span class="small ms-2">Resetar Senha</span>
                    </button>
                  </div>                  
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-5">
    <div class="row">
      <div class="col-12 col-md-4">
        <p>
          <b class="h5">Usuários Adicionais</b>
        </p>
        <p>
          Possuem permissão para instalar aplicativos, gerenciar o conteúdo das aplicações e acessar o histórico financeiro.
        </p>
        <div class="mt-4">
          <button type="button"  class="btn btn-primary btn-sm font-bold small py-2 px-3">
            <i class="bi bi-person-plus me-2"></i> <span class="small">Adicionar Usuário</span>
          </button>
        </div>
      </div>
      <div class="col">
        <div class="row row-cols-1 g-4" v-if="current_company?.users.length">
          <div class="col listing_item" v-for="item in current_company?.users" :key=item.id>
            <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
              <div class="card-body p-0">
                <div class="row align-items-center justify-content-between">
                  <div class="col d-flex align-items-center">
                    <i class="bi bi-person-lock me-2"></i> 
                    <b>{{ current_company.admin_user.first_name + ' ' + current_company.admin_user.last_name}}</b>
                    <span v-if="current_company.admin_user.id == user.id" class="badge  ms-2 text-bg-success  py-1 px-2">Este é Você</span>                    
                  </div>
                  <div class="col">
                    {{ current_company.admin_user.email }}
                  </div>
                  <div class="col-auto">
                    <button class="btn small p-0">
                      <i class="bi bi-key"></i>
                      <span class="small ms-2">Resetar Senha</span>
                    </button>
                  </div>                  
                </div>  
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col listing_item no-action">
            <div class="card d-flex flex-row align-items-start p-3" aria-hidden="true">
              <div class="card-body p-0">
                <div class="row align-items-center justify-content-between">
                  <div class="col d-flex align-items-center">
                    Nenhum usuário adicional cadastrado
                  </div>                  
                </div>  
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'PaymentHistory',
  data(){
    return{
      current_company:null
    }
  },
  computed:{
    
  },  
  methods:{
    
  },
  async created(){      
    this.$store.commit('SET_LOADING', true)       
      try{
        const req = await this.$store.dispatch('_get', {
          endpoint:'stores',     
          jwt: this.token,     
          data:{
            store: this.company.id
          }
        });        
        this.current_company = req[0]
      }catch(e){
        console.log(e)
      }
      this.$store.commit('SET_LOADING', false)
    
  }
};

</script>

<style scoped>

</style>