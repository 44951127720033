<template>
    <div id="companySetup" class="overlayPage">
      <div class="container py-5">
        <div class="row justify-content-center">
          <div class="col-12 justify-content-center text-center d-flex">
            <div class="max-600">
              <div class="d-flex justify-content-center mb-5">
                <img height="250px" src="@/assets/undraw_building_re_xfcm.svg" class="d-block"/>
              </div>
              <strong class="h2">Complete o Perfil da Sua Empresa</strong>
              <p class="mt-4">
                Para começar a aproveitar todos os recursos e funcionalidades disponíveis, pedimos que você complete o perfil da sua empresa.                
              </p>
              <!--  -->            
            </div>          
          </div>
          
        </div>
        
        <div class="row justify-content-center">
          <div class="col-5">
            <form @submit.prevent="registerCompany">
              <div class="form-floating mt-4">
                <input v-model="name" type="text" class="form-control" id="floatingName" placeholder="Nome da Empresa">
                    <label for="floatingName">Nome Fantasia*</label>
              </div>
              <div class="form-floating mt-4">
                <input v-model="url" type="text" class="form-control" id="floatingURL" placeholder="URL da Loja">
                    <label for="floatingURL">Domínio da Loja*</label>
                    <div class="form-text">Os recursos do StoreBoost irão funcionar apenas para domínio.</div>
              </div>
              <div class="form-floating mt-4">
                <input v-model="doc" type="text" class="form-control" id="floatingDoc" placeholder="CNPJ">
                    <label for="floatingDoc">CNPJ*</label>
              </div>
              <div class="form-floating mt-4">
                <select v-model="platform" class="form-select" id="floatingPlatform" placeholder="Plataforma">
                  <option value="" style="display:none">Selecione...</option>
                  <option value="Loja Integrada" >Loja Integrada</option>
                </select>
                  <label for="floatingPlatform">Plataforma*</label>
              </div>
              <div class="form-control mt-4">
                <label for="floatingURL">Logotipo</label>
                <input type="file" class="form-control-file ms-3" id="floatingLogo" multiple accept="image/*" @change="handleFileChange($event)" >
                <input v-model="logo" type="hidden">                    
              </div>
              <div class="form-image-preview mt-2 p-3" v-if="logo_preview">
                <img :src="logo_preview"/>
              </div>
              <button class="btn btn-primary btn-lg w-100 py-2 mt-4" type="submit">Salvar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  
  export default {    
    name: 'CompanySetup',
    data() {
      return {
        name:null,
        url:null,
        logo:null,  
        logo_preview:null,
        doc:null,
        platform:null,
        files:null              
      };
    },
    methods: {
      async registerCompany() {
        this.$store.commit('SET_LOADING', true)
        
        try{
          const req = await this.$store.dispatch('_post', {
            endpoint:'stores',
            jwt: this.$store.getters.token,
            data:{
              name: this.name,
              url: this.url,
              logo: this.logo,
              doc: this.doc  ,
              platform: this.platform
            }                    
          });
          const expiration = new Date();
          expiration.setDate(expiration.getDate() + 1); // Define a expiração para 1 dia a partir de agora
          console.log('req',req)
          this.$store.commit('SET_COMPANY', { company: req, expiration })
          
        }catch(e){
          console.log(e)
        }

        this.$store.commit('SET_LOADING', false)
      },
      handleFileChange(event) {    
        this.files = event.target.files;        
        console.log('files',this.files)
        this.uploadFile()
      },
      async uploadFile(){
        this.$store.commit('SET_LOADING', true)
        try {
          const formData = new FormData()
          
          for (let i = 0; i < this.files.length; i++) {
            console.log('file',this.files[i])
            formData.append('files', this.files[i]);
          }
          const response = await this.$store.dispatch('_multiform', {
            endpoint:'upload',
            jwt: this.$store.getters.token,
            headers:{'Content-Type': 'multipart/form-data'},
            data:formData                    
          });
          
          this.logo = response[0].id
          this.logo_preview = response[0].url
           
        } catch (error) {
          console.error('Erro ao buscar itens:', error);
        }
        this.$store.commit('SET_LOADING', false)
      },
    }
  };
</script>
  
<style scoped>

</style>
  