<template>
  <main class="form-signin w-100 m-auto">
    
    <form @submit.prevent="registerUser">
      <picture id="logo" class="d-block mx-auto">
        <img src="@/assets/storeboost_b.svg" class="d-block mx-auto" />
      </picture>
      <div class="row mt-5">
        <div class="col">
          <h1 class="h2">Cadastre-se</h1>
          <p>Informe seus dados abaixo e utilize o sistema por <span class="badge bg-success">7 dias</span> de forma totalmente gratuita.</p>
        </div>
      </div>
      
      <div class="row mt-4">
        <div class="col-12 col-md-6">
          <div class="form-floating">
            <input v-model="first_name" type="text" class="form-control" id="floatingFirstName" placeholder="Nome">
            <label for="floatingFirstName">Nome*</label>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-floating">
            <input v-model="last_name" type="text" class="form-control" id="floatingLastName" placeholder="Sobrenome">
            <label for="floatingLastName">Sobrenome*</label>
          </div>
        </div>
      </div>
      <div class="form-floating mt-4">
        <input v-model="doc" type="text" class="form-control" id="floatingCPF" placeholder="CPF">
            <label for="floatingCPF">CPF*</label>
      </div>
      <div class="form-floating mt-4">
        <input v-model="birth_date" type="date" class="form-control" id="floatingBirthDate" placeholder="Data de Nascimento">
            <label for="floatingBirthDate">Data de Nascimento*</label>
      </div>
      <div class="form-floating mt-4">
        <input v-model="phone" type="text" class="form-control" id="floatingPhone" placeholder="Celular">
            <label for="floatingPhone">Celular*</label>
      </div>
      <div class="form-floating mt-4">
        <select v-model="gender" class="form-select" id="floating" placeholder="CPF">
          <option value="" style="display:none">Selecione...</option>
          <option value="M" >Masculino</option>
          <option value="F" >Feminino</option>
          <option value="O" >Outros</option>
          <option value="NA" >N/A</option>
        </select>
          <label for="floating">Gênero*</label>
      </div>
      <div class="form-floating mt-4">
        <input v-model="email" type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
        <label for="floatingInput">Email*</label>
      </div>
      <div class="form-floating mt-4">
        <input v-model="password" type="password" class="form-control" id="floatingPassword" placeholder="Password">
        <label for="floatingPassword">Senha*</label>
      </div>
      <div class="form-check mt-4">
        <input v-model="lgpd" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">
          <small>Eu concordo em fornecer meus dados pessoais e compreendo que eles serão usados de acordo com a <a href="#">política de privacidade</a> da empresa. </small>
        </label>
      </div>
      <div class="form-check mt-4">
        <input v-model="terms" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">
          <small>Eu li e aceito os <a href="#">termos e condições de uso</a>.</small>
        </label>
      </div>
      
      <button class="btn btn-primary btn-lg w-100 py-2 mt-4" type="submit">Efetuar Cadastro</button>

      
    </form>
  </main>
</template>

<script>
export default {
  name: 'UserRegister',
  data() {
    return {
      username:null,
      first_name:null,
      last_name:null,
      doc:null,
      gender:null,
      birth_date:null,
      email: null,
      password: null,
      phone:null,
      lgpd:null,
      terms:null
    };
  },
  methods: {
    registerUser() {
      // Call the Vuex login action with user credentials
      this.$store.dispatch('register', {
        username: this.username,
        first_name: this.first_name,
        last_name: this.last_name,
        doc: this.doc,
        gender: this.gender,
        birth_date: this.birth_date,
        email: this.email,
        password: this.password,
        phone: this.phone,
        lgpd: this.lgpd,
        terms: this.terms
      });
    },
  },
};
</script>

<style scoped>
#logo img {
  height: 36px;
}
.form-signin {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 100vh;
  z-index: 99999999999999999;
}
.form-signin form {
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 400px;
  max-width: 100%;
}
.lost-password {
  text-decoration: none;
  color: #424242;
  border-bottom: 1px solid;
}
/* Specific styles for the login page here */
</style>
